<template>
    <section v-if="blok" v-editable="blok" class="TextTitle">
        <div class="container mx-auto">
            <div class="section-number" :class="blok.TitleAlign">{{ blok.OrderNumber }}</div>
            <component
                :is="titleTag"
                v-if="blok.title"
                class="title"
                :class="{ 'show-dividers': blok.showDividers }"
                :style="titleStyles"
            >
                {{ blok.title }}
            </component>
            <p v-if="blok.text" :style="textStyles">
                {{ blok.text }}
            </p>
        </div>
    </section>
</template>

<script setup lang="ts">
import { useIsMobileView } from '~ui/composables/isMobileView';

const { isMobileView } = useIsMobileView('768px');
import type { ITextTitle } from '~ui/types/components/TextTitle';

const { blok } = defineProps<{ blok: ITextTitle }>();

const titleTag = computed(() => (blok.TitleHeadingType ? blok.TitleHeadingType : 'h3'));

const titleStyles = computed(
    () => `${blok.color?.color ? `color: ${blok.color?.color}` : ''};
           --divider-color: ${blok.color?.color ? blok.color?.color : '#055988'};
           ${blok.TitleTopIndent || blok.TitleTopIndent === '0' ? `margin-top: ${blok.TitleTopIndent}px;` : ''}
           ${blok.TitleBottomIndent || blok.TitleBottomIndent === '0' ? `margin-bottom: ${blok.TitleBottomIndent}px;` : ''}
           ${blok.TitleFontSize ? `font-size: ${blok.TitleFontSize}px; line-height: ${Number(blok.TitleFontSize) + 2}px;` : ''}
           ${blok.TitleFontWeight ? `font-weight: ${blok.TitleFontWeight};` : ''}
           ${
               isMobileView.value
                   ? blok.TitleAlignMobile
                       ? `text-align: ${blok.TitleAlignMobile};`
                       : `text-align: ${blok.TitleAlign};`
                   : blok.TitleAlign
                     ? `text-align: ${blok.TitleAlign};`
                     : ''
           };`
);

const textStyles = computed(
    () => `${blok.TextColor?.color ? `color: ${blok.TextColor?.color};` : ''}
           ${blok.TextFontSize ? `font-size: ${blok.TextFontSize}px; line-height: ${Number(blok.TextFontSize) + 2}px;` : ''}
           ${blok.TextFontWeight ? `font-weight: ${blok.TextFontWeight};` : ''}
           ${
               isMobileView.value
                   ? blok.TextAlignMobile
                       ? `text-align: ${blok.TextAlignMobile};`
                       : `text-align: ${blok.TextAlign};`
                   : blok?.TextAlign
                     ? `text-align: ${blok.TextAlign};`
                     : ''
           };`
);
</script>

<style scoped lang="postcss">
.TextTitle {
    @apply px-4 xl:px-0;
}

.title {
    @apply whitespace-pre relative z-[2];
}

p {
    @apply mt-0 mb-4;
}
.section-number {
    @apply relative text-[11rem] font-semibold -ml-6 text-[#e3e9f1] z-0;
    &.right {
        @apply text-right -mr-2;
    }
}
</style>
